<!--门禁模块--警报页-->
<template>
  <div id="alarm" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle> {{ $t("alarm.a1") }} </my-headertitle>
      <div class="query">
        <a-row :gutter="[16,16]">
          <!-- 显示内容 -->
          <a-col :span="3">
            <a-select v-model="showContents" style="width: 100%">
              <a-select-option value="0"> {{ $t("alarm.a35") }} </a-select-option>
              <a-select-option value="1"> {{ $t("alarm.a36") }} </a-select-option>
              <a-select-option value="2"> {{ $t("alarm.a37") }} </a-select-option>
              <a-select-option value="3"> {{ $t("alarm.a38") }} </a-select-option>
            </a-select>
          </a-col>
          <!-- 楼层/租户选择 -->
          <a-col :span="3">
            <a-select v-model="form.floor" style="width: 100%">
              <a-select-option v-for="(item, value) in options" :key="value" :value="item.id"> {{ item.name }} </a-select-option>
            </a-select>
          </a-col>
          <!-- 设备名称 -->
          <a-col :span="4">
            <a-input v-model="form.name" :placeholder="$t('alarm.a2')" allow-clear v-InputText :maxLength="30" />
          </a-col>
          <!-- 日期时间 -->
          <a-col :span="10">
            <a-input-group compact>
              <!-- 初始日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="form.date1" :disabled-date="disabledStartDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" style="width: 100%"/>
              </div>
              <!-- 过渡线 -->
              <div style=" width: 10%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="form.date2" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="inquire"> {{ $t("alarm.a6") }} </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="main" v-table-size="tableSize">
      <!-- 表格标题 -->
      <my-tabletitle>
        {{ $t("alarm.a7") }}
        <template v-slot:name>
          <!-- CSV导出 -->
          <a-button type="primary" icon="file-text" size="small" ghost :disabled="data.length == 0" @click="csvExport" style="margin-right: 20px"> {{ $t("alarm.a51") }} </a-button>
          <!-- 表格表头显示内容 -->
          <a-popover placement="bottomRight">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col v-for="(item, index) in displayContent" :key="index" :span="12">
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span> {{ $t("alarm.a50") }} </span>
            </template>
            <a-button type="primary" size="small"> {{ $t("alarm.a50") }} </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <!-- 表格 -->
      <a-table :columns="column" :dataSource="data" :scroll="size" :pagination="pagination" :rowClassName="fontClass" :rowKey="(record) => record.param">
        <template slot="cameraIDList" slot-scope="text, record">
          <a-button v-if="record.cameraIDList.length > 0" type="info" icon="video-camera" shape="round" size="small" @click="openVideo(record)"/>
          <span v-else> -- </span>
        </template>
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <!-- 警报确认 -->
                <a-button type="primary" class="table_but" @click="acknowledgement(record)" > {{ $t("alarm.a9") }} </a-button>
                <!-- 手动恢复 -->
                <a-button type="primary" class="table_but" v-show="showManual(record)" @click="manualRecovery(record)" > {{ $t("alarm.a10") }} </a-button>
                <!-- 处理 -->
                <a-button type="primary" class="table_but" @click="dispose(record)" > {{ $t("alarm.a11") }} </a-button>
                <!-- 添加注释 -->
                <a-button type="primary" @click="() => annotation(record)"> {{ $t("alarm.a14")}} </a-button>
              </template>
              <a> {{ $t("alarm.a15") }} </a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 添加注释 -->
    <a-modal :title="$t('alarm.a16')" centered :visible="visible" :destroyOnClose="true" @cancel="annotationCancel" v-drag >
      <template slot="footer">
        <a-button key="back" @click="annotationCancel" :disabled="disabled"> {{ $t("alarm.a40") }} </a-button>
        <a-button key="submit" type="primary" :disabled="disabled" @click="annotationOk"> {{ $t("alarm.a41") }} </a-button>
      </template>
      <div v-loading="annotationLoading" :element-loading-text="$t('alarm.a0')">
        <a-textarea v-model="comment" :placeholder="$t('alarm.a17')" :auto-size="{ minRows: 10, maxRows: 10 }" ref="text" />
      </div>
    </a-modal>

    <!-- 处理状态变更 -->
    <a-modal :title="$t('alarm.a42')" centered :width="400" :visible="dispose_visible" :destroyOnClose="true" @cancel="disposeCancel" v-drag>
      <template slot="footer">
        <a-button key="back" @click="disposeCancel" :disabled="disabled"> {{ $t("alarm.a40") }} </a-button>
        <a-button key="submit" type="primary" :disabled="disabled" @click="disposeOk"> {{ $t("alarm.a41") }} </a-button>
      </template>
      <div v-loading="processingLoading" :element-loading-text="$t('alarm.a0')">
        <div>
          <span>{{ `${$t("alarm.a43")}${correspondenceStatus}` }}</span>
          <span></span>
        </div>
        <br />
        <div>
          <span>{{ $t("alarm.a44") }}</span>
          <a-select v-model="processingStatus" style="width: 120px">
            <a-select-option value="1"> {{ $t("alarm.a37") }} </a-select-option>
            <a-select-option value="2"> {{ $t("alarm.a45") }} </a-select-option>
            <a-select-option value="3"> {{ $t("alarm.a46") }} </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>

    <!-- 视频弹框 -->
    <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>
  </div>
</template>

<script>
import tabletitle from '../../components/Title/tabletitle'
import headerTitle from '../../components/Title/headerTitle'
import { alarmData, getTenantList, addComment, acknowledgement, manualRecovery, processing } from '../../api/door'
import { tableSort, tableTitleDisplay, tableIndexDisplay, csvDownload } from '../../utils/utils'
import cameraDialog from '../monitoring/cameraDialog.vue'
import moment from 'moment'


export default {
  name: "attendanceRecord",
  data() {
    return {
      serviceType: 3,
      visible: false,
      showContents: "0",
      dispose_visible: false,
      form: {
        name: "",
        floor: 0,
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      size: { x: 2000, y: 240 },
      options: [],
      params: "",
      comment: "",
      disabled: false,
      row: undefined,
      data: [],
      loading: false,
      annotationLoading: false,
      startDate: undefined,
      correspondenceStatus: "",
      processingStatus: "1",
      processingLoading: false,
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align: "center",
        },
        {
          id: "2",
          title: this.$t("alarm.a20"),
          dataIndex: "date",
          customRender: (text, record, index) => {
            if (record.date == null) {
              return "--";
            } else {
              return record.date;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "dateTime");
          },
          width: 170,
        },
        {
          id: "3",
          title: this.$t("alarm.a21"),
          dataIndex: "normalTime",
          customRender: (text, record, index) => {
            if (record.normalTime == null) {
              return "--";
            } else {
              return record.normalTime;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "normalTime");
          },
          width: 170,
        },
        {
          id: "4",
          title: this.$t("alarm.a22"),
          dataIndex: "notificationMode",
          customRender: (text, record, index) => {
            if (record.notificationMode == null) {
              return "--";
            } else {
              return record.notificationMode;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "notificationMode");
          },
          width: 120,
        },
        {
          id: "5",
          title: this.$t("alarm.a23"),
          dataIndex: "flcAlarmHistoryType",
          customRender: (text, record, index) => {
            if (record.flcAlarmHistoryType == null) {
              return "--";
            } else {
              return record.flcAlarmHistoryType;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "flcAlarmHistoryType");
          },
          width: 140,
        },
        {
          id: "6",
          title: this.$t("alarm.a24"),
          dataIndex: "siteName",
          customRender: (text, record, index) => {
            if (record.siteName == null) {
              return "--";
            } else {
              return record.siteName;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "siteName");
          },
          width: 140,
        },
        {
          id: "7",
          title: this.$t("alarm.a25"),
          dataIndex: "equipmentName1",
          customRender: (text, record, index) => {
            if (record.equipmentName1 == null) {
              return "--";
            } else {
              return record.equipmentName1;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName1");
          },
          width: 120,
        },
        {
          id: "8",
          title: this.$t("alarm.a26"),
          dataIndex: "cameraIDList",
          scopedSlots: { customRender: "cameraIDList" },
          // customRender: (text, record, index) => {
          //   if (record.equipmentName == null) {
          //     return "--";
          //   } else {
          //     return record.equipmentName;
          //   }
          // },
          // sorter: (a, b) => {
          //   return tableSort(a, b, "equipmentName");
          // },
          width: 60,
        },
        {
          id: "9",
          title: this.$t("alarm.a27"),
          dataIndex: "equipmentName2",
          customRender: (text, record, index) => {
            if (record.equipmentName2 == null) {
              return "--";
            } else {
              return record.equipmentName2;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName2");
          },
          width: 170,
        },
        {
          id: "10",
          title: this.$t("alarm.a28"),
          dataIndex: "eventMessage",
          customRender: (text, record, index) => {
            if (record.eventMessage == null) {
              return "--";
            } else {
              return record.eventMessage;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "eventMessage");
          },
          width: 120,
        },
        {
          id: "11",
          title: this.$t("alarm.a29"),
          dataIndex: "correspondenceStatus",
          customRender: (text, record, index) => {
            if (record.correspondenceStatus == null) {
              return "--";
            } else {
              return record.correspondenceStatus;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "correspondenceStatus");
          },
          width: 100,
        },
        {
          id: "12",
          title: this.$t("alarm.a30"),
          dataIndex: "personName",
          customRender: (text, record, index) => {
            if (record.personName == null) {
              return "--";
            } else {
              return record.personName;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          width: 100,
        },
        {
          id: "13",
          title: this.$t("alarm.a31"),
          dataIndex: "cardNo",
          customRender: (text, record, index) => {
            if (record.cardNo == null) {
              return "--";
            } else {
              return record.cardNo;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "cardNo");
          },
          width: 130,
        },
        {
          id: "14",
          title: this.$t("alarm.a32"),
          dataIndex: "bodyTemperature",
          customRender: (text, record, index) => {
            if (record.bodyTemperature == null) {
              return "--";
            } else {
              return record.bodyTemperature;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "bodyTemperature");
          },
          width: 70,
        },
        {
          id: "15",
          title: this.$t("alarm.a33"),
          dataIndex: "description",
          customRender: (text, record, index) => {
            if (record.description == null) {
              return "--";
            } else {
              return record.description;
            }
          },
          sorter: (a, b) => {
            return tableSort(a, b, "description");
          },
          width: 140,
        },
        {
          id: "16",
          title: this.$t("alarm.a34"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
      column: [],
      displayContent: JSON.parse(localStorage.getItem("alarm_tableTitle")) || [],
      cameraId: 0,
      vdialog: false,
      vmode: '1',
      starDate:new moment().startOf('day'),
      endDate:new moment()
    }
  },
  watch: {
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "alarm_tableTitle";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  // 获取警报页楼层/租户选择列表
  created() {
    this.loading = true
    getTenantList(this.$route.query.id)
      .then((res) => {
        console.log(res)
        let { data } = res
        this.options = data
        const arr = data.filter((item)=>{ if(item.tenantType === 3){ return item } })
        if(arr.length !== 0){
          this.form.floor = arr[0].id
        }else{
          this.form.floor = data[0].id
        }
        this.loading = false
      })
      .catch((err) => {
        console.log(err)
        this.loading = false
      });
  },
  mounted() {
    // 表格表头
    this.serviceType =  localStorage.getItem('serviceType')
    const name = "alarm_tableTitle"
    const columns = this.columns
    const data = tableTitleDisplay(name, columns)
    if (data.columns == undefined) {
      this.displayContent = data.displayContent
    } else {
      this.column = data.columns
      this.displayContent = data.displayContent
    }
  },
  computed: {
    // 判断需要查询的条件
    conditionNo: function () {
      if (this.form.name == "") {
        return 0
      } else {
        return 2
      }
    },
  },
  methods: {
    // 表格CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD")
      const date2 = this.form.date2.format("YYYYMMDD")
      const name =this.$t("alarm.a7")
      const fileName = `${date1}~${date2}${name}`
      //转换列表数据对象   
      csvDownload(this.data, this.columns, fileName)
    },
    moment,
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("alarm")
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight
        const tableHeight = height - 118.5 - title
        this.size.y = tableHeight
      }
    },
    // 条件查询方法
    inquire() {
      const date1 = this.form.date1
      const date2 = this.form.date2
      if(!date1 || !date2){
        this.form.date1 = moment().startOf("day")
        this.form.date2 = moment().endOf("day")
      }
      this.loading = true
      let data = {
        clientId: this.$store.getters.clientId,
        siteId: this.$route.query.id,
        tenantId: this.form.floor,
        conditionNo: this.conditionNo,
        condition: this.form.name,
        startDate: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
        endDate: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
        alarmStatus: 3,
        alarmStatus: this.showContents,
        serviceType:this.serviceType
      }
      alarmData(data)
        .then((res) => {
          this.loading = false
          let { data } = res
          this.data = data
          console.log("inquire data:",this.data)
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    // 警报确认
    acknowledgement(value) {
      this.$confirm({
        title: this.$t("alarm.a47"),
        centered: true,
        onOk: () => {
          this.loading = true;
          const data = {
            params: value.param,
          };
          acknowledgement(data)
            .then((res) => {
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.inquire();
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },
    // 手动恢复
    manualRecovery(value) {
      this.$confirm({
        title: this.$t("alarm.a48"),
        centered: true,
        onOk: () => {
          this.loading = true;
          const data = {
            params: value.param,
          };
          manualRecovery(data)
            .then((res) => {
              this.loading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.inquire();
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },
    // 应对（处理）按钮
    dispose(value) {
      this.params=value.param;//Added by li_yj on 2022-3-19 当未赋值时，处理操作失败（返回更新失败）
      this.dispose_visible = true;
      this.correspondenceStatus = value.correspondenceStatus;
    },
    // 应对（处理）的确定按钮
    disposeOk() {
      this.processingLoading = true;
      this.disabled = true;
      const data = {
        params: this.params,
        happoKind: this.processingStatus,
      };
      console.log("processing par:",data);
      processing(data)
        .then((res) => {
          this.processingLoading = false;
          this.disabled = false;
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
            this.dispose_visible = false;
            this.inquire();
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.processingLoading = false;
          this.disabled = false;
          console.log(err);
        });
    },
    // 应对（处理）的取消按钮
    disposeCancel() {
      this.dispose_visible = false;
    },
    // 打开添加注释的对话框并且获取对应ID值
    annotation(key) {
      this.visible = true;
      this.params = key.param;
      this.value = key.description;
      setTimeout(() => {
        this.$refs.text.focus();
      }, 5);
    },
    // 添加注释对话框的确定按钮
    annotationOk() {
      this.disabled = true;
      this.annotationLoading = true;
      let data = {
        params: this.params,
        comment: this.comment,
      };
      addComment(data)
        .then((res) => {
          this.disabled = false;
          this.annotationLoading = false;
          if (res.errorCode == "00") {
            this.visible = false;
            this.comment = "";
            this.$message.success(res.msg);
            this.inquire();//Added by li_yj on 2022-3-19 添加查询刷新
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.disabled = false;
          this.annotationLoading = false;
          console.log(err);
        });
    },
    // 添加注释对话框的取消按钮
    annotationCancel(e) {
      this.visible = false;
      this.comment = "";
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const currentDateValue = moment()
      if (!startValue) { return false }
      // 禁用大于计算机日期的日期值
      return startValue > currentDateValue
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startDateValue = this.form.date1
      const maxDateValue = moment(startDateValue._d).add(1, "months")
      const currentDateValue = moment().endOf("day")
      if (!endValue) { return false }
      return ( startDateValue > endValue || endValue > currentDateValue || maxDateValue < endValue)
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const currentDateValue = moment()
      const startDateValue = moment(value._d)
      const endDateValue = this.form.date2
      const maxDateValue = moment(value._d).add(1, 'months')
      // 判断初始日期时间是否空值
      if(value){
        if(endDateValue > maxDateValue){
          this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
        }else if(startDateValue > endDateValue){
          if(currentDateValue > maxDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
          }else{
            this.form.date2 = moment().endOf('day')
          }
        }
      }
    },
    // 表格字体恢复时间改变
    fontClass(record, index) {
      if (record.normalTime == null) {
        return "red";
      } else {
        return "";
      }
    },
    // 手动恢复按钮根据条件隐藏
    showManual(value) {
      let tmp = value.param.split(",");
      if (tmp[9] == "51" || tmp[9] == "13" || tmp[9] == "181") {
        if (tmp[11] == "9") {
          return true;
        }
      } else {
        return false;
      }
    },
    openVideo(record){
      console.log(record);
     
      this.cameraId = parseInt(record.cameraIDList[0]);
      let mstar = new moment(record.date).subtract(15, 'seconds');
      let mend = new moment(record.date).add(15, 'seconds');
      if(record.normalTime && '-' != record.normalTime){
        mend = new moment(record.normalTime).add(15, 'seconds');
      }
      
      this.starDate = mstar;
      this.endDate  = mend;
      this.vmode = '0';
      this.vdialog = true;

    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "camera-dialog":cameraDialog,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#alarm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.query{
  margin: 15px 0px;
}
.header {
  height: 113px;
  padding: 0 20px;
  overflow: hidden;
}
.main {
  height: calc(100% - 113px);
  width: 100%;
  padding: 0px 20px;
  overflow: hidden;
}
.table_but {
  margin-right: 15px;
}
div /deep/.red {
  color: red;
}
div /deep/.ant-form label {
  white-space: nowrap;
}
</style>